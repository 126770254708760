<template>
    <div>
        <div class="modal-body">
            <div class="row gutters">
                <div class="form-group col-6">
                    <label for="name_document">Nome do Documento</label>
                    <input type="text" id="name_document" class="form-control" placeholder="Nome do Documento"
                           v-model="filter.name_document">
                </div>
                <div class="form-group col-6">
                    <label for="signer">Signatário</label>
                    <input type="text" id="signer" class="form-control" placeholder="Signatário"
                           v-model="filter.signer">
                </div>
                <div class="form-group col-6">
                    <label for="signer">E-mail do Signatário</label>
                    <input type="text" id="email" class="form-control" placeholder="email"
                           v-model="filter.email">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn-secondary btn" @click="clearFilter()">Limpar</button>
            <button class="btn-primary btn" @click="$emit('filtering', filter)">Filtrar</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "filterComponent",
    data() {
        return {
            filter: {},
        }
    },
    methods:{
        clearFilter(){
            this.filter = {};
            this.$emit('clearFilter');
        }
    }
}
</script>

<style scoped>

</style>