<template>
  <div class="card">
    <div v-for="(iten, key) in items" :key="iten.id" :class="key !=0 ? 'border-top2': ''" >
      <div class="row space-between mt-2" style="margin-left: -22px;">
        <div :class="sizePrincial">
          <slot name="principal-info" :items="{iten, key}"></slot>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 ml-negative-1" v-if="haveDetails">
          <div class="color-grey">
            <a class="button-center">
              <i class="icon-keyboard_arrow_down pointer" @click="$emit('open', iten)" v-if="!iten.open"></i>
              <i class="icon-keyboard_arrow_up pointer" @click="$emit('open', iten)" v-if="iten.open"></i>
            </a>
          </div>
        </div>
        <div class="expiration-date" v-if="!haveData">
          <slot name="date" :iten="iten"></slot>
        </div>
        <div class="button-options" v-if="haveOptions">
          <div class="text-right mr-3">
            <a type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size: 20px" id="listDropdown">
              <i class="icon-more_vert"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right dropdown-black" id="dropdownMenu" aria-labelledby="listDropdown">
              <slot name="dropdown-options" :iten="iten"></slot>
            </div>
          </div>
        </div>
      </div>
      <transition name="fade" v-if="haveDetails">
        <div v-show="iten.open">
          <div class="table-settings border-top2 detail-shadow-t">
            <div class="detail">
              <slot name="details-itens" :iten="iten"></slot>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: "listComponent",
  props: {
    items: {
      type: Object,
      required: false,
    },
    haveDetails: {
      type: Boolean,
      default: false
    },
    haveOptions: {
      type: Boolean,
      default: true
    },
    haveData: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      sizePrincial: null,
    }
  },

  watch: {
    items: function() {
      this.princialSize();
    }
  },

  mounted() {
    this.princialSize();
  },

  methods: {
    princialSize() {
      if (this.haveDetails) {
        this.sizePrincial = 'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6';
      } else if (this.haveOptions) {
        this.sizePrincial = 'col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10';
      } else {
        this.sizePrincial = 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12';
      }
    },

    formatDate(date){
      if(date){
        return  moment(String(date)).format('DD/MM/YYYY H:mm')
      }
      return "";
    },

  }
}
</script>

<style>

.expiration-date {
  position: relative;
  right: 60px;
}
.button-options {
  position: relative;
  right: 50px;
}
.button-center {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #333333;
  border-radius: 50px;
  font-size: 160%;
}
.table-settings {
  padding-top: 0.6rem !important;
  padding-right: 1rem !important;
  padding-bottom: 0.1rem !important;
  background-color: white !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.space-between {
  padding-bottom: 8px;
  align-items: center;
  justify-content: space-between;
}
.font-seven {
  font-size: 0.7rem !important;
}
.detail {
  padding-left: 2%;
  padding-right: 2%;
  align-items: center;
  justify-content: space-between;
}
.dropdown-menu-custom {
  right: 0px !important;
  left: 13px !important;
  width: 7rem !important;
  min-width: 7rem !important;
}
.ml-negative-1 {
  margin-left: -3.5%;
  padding-left: 0px
}
</style>
